import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
  standalone: false,
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
  @Input() compact = false;
  @Input() multiple = true;
  @Input() hidden = false;
  @Input() fileAccept = '.pdf,.png,.jpeg,.jpg,.gif';
  @Input() fileSizeLimit = 5000000;
  @Input() fileTypeName = 'Dokumente';
  @Input() useCustomUpload = false;

  @Output() uploadReady = new EventEmitter<FileList>();

  public errorMessage = '';

  public snackBarMessage: string;

  private errorMessageSub: Subscription;
  private filteredArray = [];
  private notAllowed = [];

  constructor(
    private documentService: DocumentService,
    private contextService: ContextService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  ngOnInit(): void {
    this.errorMessageSub = this.documentService.errorMessage.subscribe(
      errorMessage => (this.errorMessage = errorMessage)
    );
  }

  ngOnDestroy(): void {
    this.documentService.errorMessage.next('');
    this.errorMessageSub?.unsubscribe();
  }

  public resetPath(event: any): void {
    this.documentService.errorOccured.next(false);
    this.documentService.errorMessage.next('');
    event.target.value = null;
  }

  public triggerUploadDialog(): void {
    const input = document.getElementById('bgzv-document-upload') as HTMLInputElement;
    if (!!input) {
      input.click();
    }
  }

  public onFileSelect(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.filteredArray = Array.from(target.files);
    this.checkBeforeUpload(target.files);
  }

  public onFileDrop(files: FileList): void {
    this.filteredArray = Array.from(files);
    this.checkBeforeUpload(files);
  }

  private checkBeforeUpload(files: FileList): void {
    this.notAllowed = [];
    const allowedTypes = this.fileAccept.split(',').map(value => {
      return `${value === '.pdf' ? 'application' : 'image'}/${value.substring(1)}`;
    });

    for (let i = 0; i < this.filteredArray.length; i++) {
      if (!allowedTypes.includes(this.filteredArray[i].type)) {
        this.notAllowed.push(this.filteredArray[i].name);

        this.filteredArray.splice(i, 1);
      }
    }

    const tooBigFileExists = this.filteredArray.find(x => x.size > this.fileSizeLimit);
    let tooBigErrorMessage = '';
    if (tooBigFileExists) {
      tooBigErrorMessage = `Die Datei ${tooBigFileExists.name} ist zu groß. Dateien dürfen maximal ${this.maxSize} groß sein.`;
    }

    let notAllowedErrorMessage = '';
    if (this.notAllowed.length > 0) {
      notAllowedErrorMessage = `Die Datei${this.notAllowed.length > 1 ? 'en' : ''} "${this.notAllowed.join(
        ', '
      )}" entspr${
        this.notAllowed.length > 1 ? 'echen' : 'icht'
      } nicht dem erlaubten Format. Bitte beachten Sie, dass nur folgende Dateitypen unterstützt werden: ${this.fileAccept}`;
    }

    let errorOccured = false;
    if (tooBigFileExists && !!notAllowedErrorMessage.length) {
      const commonErrorMessage = 'Einige Dateien entsprechen nicht den Bedingungen und werden übersprungen.';
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: commonErrorMessage });
      errorOccured = true;
    } else if (tooBigFileExists && !notAllowedErrorMessage.length) {
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: tooBigErrorMessage });
      errorOccured = true;
    } else if (!tooBigFileExists && !!notAllowedErrorMessage.length) {
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: notAllowedErrorMessage });
      errorOccured = true;
    }

    if (this.useCustomUpload) {
      if (!errorOccured) {
        this.uploadReady.emit(files);
      }
    } else {
      this.documentService.uploadDocuments(files);
    }
  }

  get maxFileSize(): number {
    return this.fileSizeLimit;
  }

  get maxSize(): string {
    let size = this.fileSizeLimit / 1000000;

    if (size < 1) {
      return `${this.fileSizeLimit / 1000}kb`;
    } else {
      return `${size}Mb`;
    }
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
