@if (transitionData) {
  <section
    class="composition-grid {{ showMainDetails ? 'mt-2 pt-4' : !mainData || !showMainDetails ? 'mt-2' : 'pt-4' }}"
  >
    @if (showMainDetails || showIndividualGroupHeading) {
      @if (compositionMedia && compositionMedia.url) {
        <bgzv-image
          class="composition-image"
          [alt]="'illustration'"
          [src]="compositionMedia.url"
          aria-hidden="true"
        ></bgzv-image>
      } @else {
        <bgzv-image
          class="composition-image"
          [alt]="'illustration'"
          [src]="assetPath + '/images/illu_zahnrad_duotone.svg'"
          aria-hidden="true"
        ></bgzv-image>
      }
    }
    @if (!showMainDetails && !showIndividualGroupHeading) {
      <div></div>
    }
    <div>
      @if (showIndividualGroupHeading) {
        <div class="d-flex align-items-center mb-2">
          <h2 class="text-truncate mr-3 mb-0" [style.maxWidth.%]="90">Individuelle Expertengespräche</h2>
        </div>
      }
      <ng-container *ngTemplateOutlet="header; context: { data: transitionData }"></ng-container>
      <ng-container *ngTemplateOutlet="content; context: { data: transitionData }"></ng-container>
    </div>
  </section>
}

<ng-template #header let-data="data">
  @if (showMainDetails) {
    <div class="d-flex align-items-center mb-2">
      @if (mainData?.name && !isIndividualItem) {
        <h2
          [debugId]="mainData.id"
          class="mr-2 mb-0"
          [style.maxWidth.%]="90"
          [innerHTML]="truncateText(mainData.name, 50) | safeHtml"
          [kfTooltip]="mainData.name.length > 50 ? mainData.name : null"
          kfTooltipPosition="below"
          kfTooltipShow="hover"
        ></h2>
      }
      @if (data?.name && !isIndividualItem) {
        <h2
          [debugId]="data.id"
          class="mr-2 mb-0"
          [style.maxWidth.%]="90"
          [innerHTML]="truncateText(data.name, 50) | safeHtml"
          [kfTooltip]="data.name.length > 50 ? data.name : null"
          kfTooltipPosition="below"
          kfTooltipShow="hover"
        ></h2>
      }
      @if (!!mainData?.id) {
        <button mat-button (click)="onBadgeClicked('product', mainData.id, $event)">
          <span>{{ mainData.name }}</span>
          <kf-icon name="ic_weiterleiten_24"></kf-icon>
        </button>
      }
    </div>
  }
</ng-template>

<ng-template #content let-transitionData="data">
  <div class="composition-transition-border">
    <div class="d-flex align-items-center composition-container">
      <ng-container *ngTemplateOutlet="productItem; context: { data: transitionData }"></ng-container>
      <ng-container *ngTemplateOutlet="assignItem; context: { data: transitionData }"></ng-container>
      @if (!isIndividualItem) {
        <ng-container *ngTemplateOutlet="infoIcon; context: { data: transitionData }"></ng-container>
      }
    </div>
    <ng-container *ngTemplateOutlet="appointmentItem; context: { data: transitionData }"></ng-container>
  </div>
</ng-template>

<ng-template #productItem let-data="data">
  <div class="d-flex align-items-center flex-grow-1">
    <mat-checkbox
      #transitionCheckbox
      [disableRipple]="true"
      [disabled]="loading"
      [checked]="data.selected || !compositionId"
      (click)="$event.stopPropagation()"
      (change)="selectTransition(data.id, $event)"
      style="margin: 0 0.5rem 0 0"
      [color]="'grey'"
      ><span [innerHTML]="truncateText(data.name, 50) || 'NO TITLE' | safeHtml"></span
    ></mat-checkbox>
    @if (isRecommended(data)) {
      <div class="tag-info tag-neutral mb-1 ml-3">
        <div class="tag-info-text">Empfohlen</div>
      </div>
    }
  </div>
</ng-template>

<ng-template #assignItem let-data="data">
  @if (data.selected) {
    @if (data?.appointments?.length === 0) {
      <button
        [class.mr-4]="!isIndividualItem"
        [attr.data-testcafe]="'itemCompositionTransition-button-dateSuggestion'"
        mat-stroked-button
        color="primary"
        (click)="openTransitionAssignment(data.id, true, data.selected)"
      >
        <span>Termin vorschlagen</span>
      </button>
    } @else if (data?.appointments?.length > 0) {
      <button
        [class.mr-4]="!isIndividualItem"
        [attr.data-testcafe]="'itemCompositionTransition-button-appointment'"
        mat-stroked-button
        color="primary"
        (click)="openTransitionAssignment(data.id, true, data.selected)"
      >
        <span [ngPlural]="data.appointments.length">
          <ng-template ngPluralCase="=1">Terminvorschlag</ng-template>
          <ng-template ngPluralCase="other">Terminvorschläge</ng-template>
          ändern
        </span>
      </button>
    }
  }
</ng-template>

<ng-template #appointmentItem let-data="data">
  @if (!!data.customerContact) {
    <div class="d-flex align-items-center mat-small mt-3">
      <div class="d-flex align-items-center">
        <kf-icon name="ic_personen_paar_24" class="mr-2"></kf-icon>
        <div>{{ formatContact(transitionData.customerContact) }}</div>
      </div>
      @if (!!data.appointments) {
        <div class="mx-2">•</div>
        <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
        <div>
          {{ data.appointments.length }} {{ data.appointments.length > 1 ? 'Terminvorschläge' : 'Terminvorschlag' }}
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #infoIcon let-data="data">
  <button
    class="ml-3"
    [attr.data-testcafe]="'itemComposition-button-details'"
    mat-icon-button
    (click)="openTransitionAssignment(transitionData.id, false, transitionData.selected)"
    aria-label="Details anzeigen"
    [kfTooltip]="'Details zu: ' + data.name"
    kfTooltipPosition="below"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
  </button>
</ng-template>
