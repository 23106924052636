import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSlider, MatSliderDragEvent } from '@angular/material/slider';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { AnswerOptionItem } from '@domain/app/answers.domain';
import { QuestionGroupResponse, QuestionItem } from '@domain/app/question.domain';
import { QuestionEnum } from '@enums';
import { SliderProperties, ValueObject } from '@screens/screen-consultation/screen-consultation.interfaces';

@Component({
  selector: 'answer-slider',
  imports: [MaterialModule, KfThemeModule, ReactiveFormsModule, MatInputModule, CommonModule],
  templateUrl: './answer-slider.component.html',
  styleUrl: './answer-slider.component.scss',
})
export class AnswerSliderComponent {
  @ViewChild('slider') slider!: MatSlider;

  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() defaultValue: number;
  @Input() showLabels: boolean = false;
  @Input() showTextInput: boolean = false;
  @Input() description: string;
  @Input() textInputLabel: string;

  //Inputs only for slider as answer to questions:
  @Input() question: QuestionItem;
  @Input() sliderPropertiesMap: Map<string, SliderProperties>;
  @Input() numericalAnswerData: Map<string, ValueObject>;
  @Input() questionAnswer: boolean = false;

  @Output() valueSelected = new EventEmitter<any>();

  private currentValue = 0;
  private minPower = 0;
  private maxPower = 0;

  public step: number;
  public showTickMarks: boolean;
  public labelArray = [];
  public stepArray = [];
  public hasTextField: boolean;
  public numRegex: RegExp;
  public maxNotPowTen: boolean;
  public showInfo = false;
  public hasAnswerDesc: boolean;
  public isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  public questionGroupData: QuestionGroupResponse;
  public questionEnum = QuestionEnum;
  public sliderFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.sliderFormGroup = new FormGroup({
      text: new FormControl<string>(this.min.toString(), this.validator()),
      slider: new FormControl<number>(0),
      connectedSlider: new FormControl<number>(0),
      expText: new FormControl<string>(this.min.toString(), this.validator()),
      expSlider: new FormControl<number>(0),
      note: new FormControl<string>(''),
      simpleSlider: new FormControl<number>(this.min, this.validator()),
    });

    if (this.questionAnswer) {
      this.currentValue = this.numericalAnswerData.get(this.question.id).value;
      this.step = this.getStepValue();
      this.minPower = this.sliderPropertiesMap.get(this.question.id).minPower;
      this.maxPower = this.sliderPropertiesMap.get(this.question.id).maxPower;
      this.hasTextField = this.sliderPropertiesMap.get(this.question.id).hasTextField;
      this.maxNotPowTen = this.max !== Math.pow(10, this.maxPower);
      this.hasAnswerDesc = !!this.question.answers.find(x => x.tooltip);

      this.sliderFormGroup.patchValue({
        text: this.numericalAnswerData.get(this.question.id).value,
        expText: this.numericalAnswerData.get(this.question.id).value,
        note: this.numericalAnswerData.get(this.question.id).note,
      });

      if (this.question.type === this.questionEnum.expSlider) {
        this.expTextToSlider();
        this.labelArray = this.sliderPropertiesMap.get(this.question.id).expSliderLabels;
        this.stepArray = this.sliderPropertiesMap.get(this.question.id).expSliderSteps;
      } else {
        this.textToSlider();

        if (this.max / this.step <= 10) {
          this.labelArray.push(this.min);
          for (let i = this.min + this.step; i <= this.max; i = i + this.step) {
            this.labelArray.push(i);
          }
          this.showTickMarks = true;
        }
      }
    } else {
      this.maxPower = Math.floor(Math.log10(this.max));
      this.minPower = this.min !== 0 ? Math.floor(Math.log10(this.min)) : 0;
      this.sliderFormGroup.patchValue({ simpleSlider: this.defaultValue || this.min });
    }
  }

  /***** DISPLAY DATA *****/

  public textToSlider() {
    this.currentValue = this.sliderFormGroup.value.text || this.min;
    this.sliderFormGroup.patchValue({
      slider: this.currentValue,
      connectedSlider: this.max - this.currentValue,
    });
  }

  public sliderToText(event: MatSliderDragEvent, isConnectedSlider: boolean) {
    if (!isConnectedSlider) {
      this.currentValue = event.value;
      this.sliderFormGroup.patchValue({
        text: this.currentValue,
        connectedSlider: this.max - this.currentValue,
      });
    } else {
      this.currentValue = this.min === 0 ? this.max - event.value : this.max - (event.value - 1);
      this.sliderFormGroup.patchValue({
        text: this.currentValue,
        slider: this.currentValue,
      });
    }
  }

  public expTextToSlider() {
    this.currentValue = this.sliderFormGroup.value.expText || this.min;
    let sliderValue = this.currentValue;
    if (sliderValue <= 0) {
      this.sliderFormGroup.patchValue({ expSlider: 0 });
      return;
    }

    let powerOfTen = Math.pow(10, Math.floor(Math.log10(sliderValue)));
    sliderValue = Math.round(sliderValue / powerOfTen) * powerOfTen; //round to first digit

    const sliderStep = this.sliderPropertiesMap.get(this.question.id).expSliderSteps.indexOf(sliderValue);
    this.sliderFormGroup.patchValue({ expSlider: sliderStep });
  }

  public expSliderToText(event: MatSliderDragEvent) {
    this.currentValue = this.sliderPropertiesMap.get(this.question.id).expSliderSteps[event.value];
    this.sliderFormGroup.patchValue({ expText: this.currentValue });
  }

  public getCurrentAnswer(): AnswerOptionItem {
    let answer: AnswerOptionItem;
    answer = this.question.answers.find(x => x.maxValue >= this.currentValue && x.minValue <= this.currentValue);
    return answer;
  }

  /***** EMIT DATA *****/

  public emitAnswerSlider(controlName?: string) {
    if (this.sliderFormGroup.controls[controlName].valid) {
      this.valueSelected.emit({ value: this.currentValue, noteInputValue: this.sliderFormGroup.value.note || '' });
    }
  }

  public handleSimpleData() {
    this.valueSelected.emit({ value: this.sliderFormGroup.value.simpleSlider });
  }

  /***** ADJUST HTML PRESENTATION *****/

  public getMargin(index: number): string {
    const expSliderWidth = document.getElementById('expSlider')?.offsetWidth;
    if (!expSliderWidth) {
      return '';
    }
    const stepWidth = expSliderWidth / this.stepArray.length;

    if (index === 0) {
      return 'margin-right: ' + stepWidth + 'px';
    } else {
      let numOfSteps = this.max / Math.pow(10, this.maxPower); //number of steps to compensate with negative margin
      return 'margin-left: ' + -stepWidth * numOfSteps + 'px';
    }
  }

  public getLabelPositionsExp(index: number): string {
    const expSliderWidth = document?.getElementById('expSlider')?.offsetWidth || 0;
    let labelDistance: number;

    if ((this.min !== 0 || index === 0) && !this.maxNotPowTen) {
      //power of ten on pos 0 -> equal distances to following powers
      labelDistance = expSliderWidth / (this.maxPower - this.minPower);
      return 'left: ' + labelDistance * index + 'px';
    }

    let distOne: number;
    const stepWidth = expSliderWidth / (this.stepArray.length - 1);
    labelDistance = (expSliderWidth - stepWidth) / (this.maxPower - this.minPower);

    if (this.maxNotPowTen) {
      //number of steps of last interval * stepWidth
      const lastIntervalWidth = (this.max / Math.pow(10, this.maxPower)) * stepWidth;
      const sectionWidth = expSliderWidth - lastIntervalWidth;
      const subSectionWidth = sectionWidth / (this.labelArray.length - 2);
      labelDistance = subSectionWidth * index;

      if (this.min === 0) {
        distOne = subSectionWidth + stepWidth;

        switch (index) {
          case 0:
            return '';
          case 1:
            return 'left: ' + distOne + 'px';
          case this.labelArray.length - 1:
            return 'left: ' + expSliderWidth + 'px';
          default:
            return 'left: ' + (distOne + labelDistance) + 'px';
        }
      } else {
        switch (index) {
          case 0:
            return '';
          case this.labelArray.length - 1:
            return 'left: ' + expSliderWidth + 'px';
          default:
            return 'left: ' + labelDistance + 'px';
        }
      }
    } else {
      distOne = labelDistance + stepWidth;

      //first range 1 step bigger because of 0
      if (index === 1) {
        return 'left: ' + distOne + 'px';
      }

      labelDistance = ((expSliderWidth - distOne) / (this.labelArray.length - 2)) * (index - 1);
      return 'left: ' + (distOne + labelDistance) + 'px';
    }
  }

  public getLabelPositionsLinear(index: number): string {
    const sliderWidth = this.slider?._trackActive?.nativeElement?.offsetWidth || 0;
    const stepWidth = sliderWidth / ((this.max - this.min) / this.step);
    return 'left: ' + stepWidth * index + 'px';
  }

  public getStepValue(): number {
    if (this.question.stepValue === 0) {
      return 1;
    } else {
      return this.question.stepValue;
    }
  }

  public getNumInputError(): string {
    return (
      'Bitte geben Sie einen Wert zwischen ' + this.min.toLocaleString() + ' und ' + this.max.toLocaleString() + ' an.'
    );
  }

  public onChange(controlName: string): void {
    const control = this.sliderFormGroup.controls[controlName];
    if (control.errors) return;
    if (controlName === 'simpleSlider') this.handleSimpleData();
    else {
      if (controlName === 'text') {
        this.textToSlider();
      } else if (controlName === 'expText') {
        this.expTextToSlider();
      }
      this.emitAnswerSlider(controlName);
    }
  }

  private validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const valStr = value ? value.toString() : '';
      const isDecimal = valStr.includes('.') || valStr.includes(',');
      const invalid = value === undefined || isDecimal || isNaN(value) || value < this.min || value > this.max;
      return invalid ? { invalid: true } : null;
    };
  }

  /***** GETTER *****/

  get testcafeLabel(): string {
    if (this.question) {
      return this.question.name.split(' #/#')[0];
    } else {
      return '';
    }
  }

  get firstLabelDependent(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[1] : '';
  }

  get secondLabelDependent(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[2] : '';
  }

  get labelMain(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[0] : this.question.name;
  }
}
