<!-- SLIDER & CONNECTEDSLIDER -->
<form [formGroup]="sliderFormGroup">
  @if (questionAnswer) {
    @if (question?.type === questionEnum?.slider || question?.type === questionEnum?.connectedSlider) {
      <mat-form-field>
        @if (question?.type === questionEnum?.connectedSlider) {
          <mat-label>{{ this.question.answers[0].label }}</mat-label>
        } @else {
          <mat-label>Ihre Eingabe</mat-label>
        }
        <input
          matInput
          #sliderInput
          autocomplete="off"
          inputmode="numeric"
          formControlName="text"
          role="textbox"
          [attr.aria-label]="'Werteingabe ' + question.id"
          [attr.data-testcafe]="'slider-description-' + testcafeLabel"
          (change)="onChange('text')"
        />
        @if (hasAnswerDesc) {
          <mat-hint class="info" [ngClass]="{ 'info--hidden': !getCurrentAnswer()?.tooltip }">
            <ng-template
              *ngTemplateOutlet="infoIcon; context: { description: getCurrentAnswer()?.tooltip }"
            ></ng-template>
          </mat-hint>
        }
        <mat-error>
          @if (sliderFormGroup.get('text').invalid) {
            <kf-input-error [message]="getNumInputError()" />
          }
        </mat-error>
      </mat-form-field>
      <div>{{ firstLabelDependent }}</div>
      <mat-slider
        #slider
        [id]="'slider_' + question.id"
        [min]="this.min"
        [max]="this.max"
        [step]="step"
        [showTickMarks]="showTickMarks"
      >
        <input
          matSliderThumb
          autocomplete="off"
          formControlName="slider"
          [attr.aria-label]="'Sliderbutton ' + question.id"
          #sliderThumb="matSliderThumb"
          (change)="emitAnswerSlider('text')"
          (input)="sliderToText({ parent: slider, source: sliderThumb, value: sliderThumb.value }, false)"
        />
      </mat-slider>
      @if (showTickMarks && question?.type === questionEnum?.slider) {
        <ul class="d-flex list-unstyled slider-label-container">
          @for (label of this.labelArray; track label; let index = $index) {
            <li class="slider-label slider-label--linear" [style]="getLabelPositionsLinear(index)">
              {{ label.toLocaleString() }}
            </li>
          }
        </ul>
      } @else {
        <ul class="d-flex justify-content-between list-unstyled slider-label-container">
          <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
          <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
        </ul>
      }
      @if (this.question.type === this.questionEnum.connectedSlider) {
        <!-- CONNECTEDSLIDER -->
        <div class="mt-5">{{ secondLabelDependent }}</div>
        <mat-slider #connectedSlider [min]="this.min" [max]="this.max" [step]="step">
          <input
            matSliderThumb
            #connectedSliderThumb="matSliderThumb"
            formControlName="connectedSlider"
            [attr.aria-label]="'Verknüpfter Sliderbutton ' + question.id"
            autocomplete="off"
            (change)="emitAnswerSlider('text')"
            (input)="
              sliderToText(
                { parent: connectedSlider, source: connectedSliderThumb, value: connectedSliderThumb.value },
                true
              )
            "
          />
        </mat-slider>
        <ul class="d-flex justify-content-between list-unstyled slider-label-container">
          <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
          <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
        </ul>
      }
    } @else if (this.question.type === this.questionEnum.expSlider) {
      <!-- DECLOGSLIDER -->
      <mat-form-field>
        <mat-label>Ihre Eingabe</mat-label>
        <input
          matInput
          #expSliderInput
          inputmode="numeric"
          autocomplete="off"
          formControlName="expText"
          [attr.aria-label]="'Nummereingabe ' + question.id"
          role="textbox"
          [attr.data-testcafe]="'slider-description-' + testcafeLabel"
          (change)="onChange('expText')"
        />
        @if (hasAnswerDesc) {
          <mat-hint class="info" [ngClass]="{ 'info--hidden': !getCurrentAnswer()?.tooltip }">
            <ng-template
              *ngTemplateOutlet="infoIcon; context: { description: getCurrentAnswer()?.tooltip }"
            ></ng-template>
          </mat-hint>
        }
        <mat-error>
          @if (sliderFormGroup.get('expText').invalid) {
            <kf-input-error [message]="getNumInputError()" />
          }
        </mat-error>
      </mat-form-field>
      <mat-slider
        #expSlider
        id="expSlider"
        [min]="this.sliderPropertiesMap.get(question.id).expSliderMin"
        [max]="this.sliderPropertiesMap.get(question.id).expSliderMax"
        [step]="step"
      >
        <input
          matSliderThumb
          #expSliderThumb="matSliderThumb"
          autocomplete="off"
          [attr.aria-label]="'Sliderbutton ' + question.id"
          formControlName="expSlider"
          (change)="emitAnswerSlider('expText')"
          (input)="expSliderToText({ parent: expSlider, source: expSliderThumb, value: expSliderThumb.value })"
        />
      </mat-slider>
      <div class="d-flex justify-content-between tick-mark-container">
        @for (mark of this.labelArray; track mark; let index = $index) {
          @if (min === 0 && index === 0) {
            <!--push following marks by 1 step-width for 0-->
            <div class="tick-mark" [style]="getMargin(index)"></div>
          } @else if (maxNotPowTen && index === this.labelArray.length - 1) {
            <div class="tick-mark" [style]="getMargin(index)"></div>
          } @else {
            <div class="tick-mark"></div>
          }
        }
      </div>
      <ul class="d-flex list-unstyled slider-label-container">
        @for (label of this.labelArray; track label; let index = $index) {
          @if (index === 0) {
            <li
              id="label-{{ index }}"
              class="slider-label slider-label--exp-first"
              [style]="getLabelPositionsExp(index)"
            >
              {{ label.toLocaleString() }}
            </li>
          } @else if (index === this.labelArray.length - 1) {
            <li
              id="label-{{ index }}"
              class="slider-label slider-label--exp-last"
              [style]="getLabelPositionsExp(index)"
            >
              {{ label.toLocaleString() }}
            </li>
          } @else {
            <li id="label-{{ index }}" class="slider-label slider-label--exp" [style]="getLabelPositionsExp(index)">
              {{ label.toLocaleString() }}
            </li>
          }
        }
      </ul>
    }
  } @else {
    <!--NON-CONSULTATION SLIDER (e.g. account-compare)-->
    @if (showTextInput) {
      <mat-form-field>
        <mat-label>{{ textInputLabel }}</mat-label>
        <input
          #simpleSliderInput
          inputmode="numeric"
          autocomplete="off"
          formControlName="simpleSlider"
          role="textbox"
          [attr.aria-label]="'Texteingabe ' + textInputLabel"
          [attr.data-testcafe]="'slider-description-' + testcafeLabel"
          [value]="sliderFormGroup.value.simpleSlider"
          (change)="onChange('simpleSlider')"
          matInput
        />
        @if (description) {
          <mat-hint>
            <ng-template *ngTemplateOutlet="infoIcon; context: { description: description }"></ng-template>
          </mat-hint>
        }

        <mat-error>
          @if (sliderFormGroup.get('simpleSlider').invalid) {
            <kf-input-error [message]="getNumInputError()" />
          }
        </mat-error>
      </mat-form-field>
    }

    <mat-slider #simpleSlider id="simpleSlider" [min]="min" [max]="max" [step]="step">
      <input
        matSliderThumb
        formControlName="simpleSlider"
        #simpleSliderThumb="matSliderThumb"
        autocomplete="off"
        [attr.aria-label]="'Simple Slider' + textInputLabel"
        [value]="sliderFormGroup.value.simpleSlider"
        (input)="handleSimpleData()"
      />
    </mat-slider>
    @if (showLabels) {
      <ul class="d-flex justify-content-between list-unstyled slider-label-container">
        <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
        <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
      </ul>
    }
  }
  @if (this.hasTextField) {
    <mat-form-field>
      <mat-label>Notizen</mat-label>
      <textarea
        #sliderNote
        formControlName="note"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"
        autocomplete="off"
        [attr.aria-label]="'slider-textarea-' + question.id"
        [attr.data-testcafe]="'slider-note-' + this.testcafeLabel"
        (change)="emitAnswerSlider('simpleSlider')"
        matInput
      ></textarea>
    </mat-form-field>
  }
</form>

<ng-template #infoIcon let-description="description">
  <kf-icon
    [kfTooltip]="description"
    [attr.data-testcafe]="'slider-infoIcon-' + this.testcafeLabel"
    alt="Details"
    class="info--icon"
    kfTooltipPosition="after"
    kfTooltipShow="hover"
    [kfTooltipDisabled]="isTouchDevice"
    name="ic_infozeichen-mit-kreis_24"
    role="button"
    tabindex="0"
    (click)="showInfo = !isTouchDevice ? false : !showInfo"
  ></kf-icon>
  @if (showInfo) {
    <span>{{ description }}</span>
  }
</ng-template>
