<div class="container">
  <div class="row">
    <div class="mx-auto my-5 col-6">
      <kf-tile [interactive]="false">
        <div class="p-3 d-flex flex-column align-items-center justify-content-center success-box">
          <kf-icon class="success-icon" name="ic_haken_mit_kreis_96"></kf-icon>
          <h2>{{ isTestConsultation ? 'Die Testberatung ist beendet.' : 'Das Beratungsmodul ist abgeschlossen.' }}</h2>
          <p>Vielen Dank!</p>
        </div>
        <div class="p-3 d-flex flex-column align-items-center justify-content-center">
          <h3 class="mat-body-1 font-weight-bold">Wie geht es weiter?</h3>
          @if (platform === 'aws') {
            @if (!isTestConsultation) {
              @if (!requestInProgress) {
                <button
                  [attr.data-testcafe]="'finishScreen-button-completeConsultation'"
                  class="mt-4"
                  mat-stroked-button
                  color="primary"
                  (click)="finishConsultation()"
                >
                  <span>Zur Beratungsübersicht</span>
                </button>
              } @else {
                <mat-spinner
                  [diameter]="24"
                  color="primary"
                  kfTooltip="Beratung wird abgeschlossen..."
                  kfTooltipPosition="below"
                  kfTooltipShow="hover"
                ></mat-spinner>
              }
            } @else {
              Sie können die Anwendung jetzt schließen.
            }
          } @else if (platform === 'vp') {
            @if (!requestInProgress) {
              <button
                [attr.data-testcafe]="'finishScreen-button-completeConsultation'"
                class="mt-4"
                mat-stroked-button
                color="primary"
                (click)="finishConsultation()"
              >
                <span>Zur Gesprächsübersicht</span>
              </button>
            } @else {
              <mat-spinner
                [diameter]="24"
                color="primary"
                kfTooltip="Beratung wird abgeschlossen..."
                kfTooltipPosition="below"
                kfTooltipShow="hover"
              ></mat-spinner>
            }
          }
        </div>
      </kf-tile>
    </div>
  </div>
</div>
