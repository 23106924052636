@if (dataFieldForm) {
  <form [formGroup]="dataFieldForm" class="mb-4">
    @for (
      group of dataFieldData?.dataFieldGroups;
      track group.name;
      let i = $index;
      let last = $last;
      let first = $first
    ) {
      <ng-container formArrayName="datafields">
        @if (hasVisibleFields(group.dataFields)) {
          <!-- NON-MULTIPLIED DATA FIELD GROUPS-->
          @if (!group?.multiplied) {
            <div class="w-100 datafield-divider" [class.datafield-divider]="!last">
              <h3 class="mat-body-1 font-weight-bold text-nowrap mb-0 mr-4">
                {{ group.name }}
              </h3>
              <div [class.description]="group.description" [innerHTML]="group.description | safeHtml"></div>
              <ng-template
                *ngTemplateOutlet="fields; context: { group, formGroup: getFormGroup(i), last }"
              ></ng-template>
            </div>
            <!-- MULTIPLIED DATA FIELD GROUPS WITHOUT CONTRACTS-->
          } @else if (!getHasContractForm(group)) {
            <ng-template
              *ngTemplateOutlet="panel; context: { group, formGroup: getFormGroup(i), i, last, first }"
            ></ng-template>
          }
        }
      </ng-container>
    }

    <!-- MULTIPLIED DATA FIELD GROUPS WITH CONTRACTS -->
    @for (group of dataFieldData.dataFieldGroups; track group.name; let i = $index; let last = $last) {
      @if (group.multiplied && group.hasContractForm) {
        <ng-container formArrayName="datafields">
          <ng-template
            *ngTemplateOutlet="
              panelContract;
              context: {
                group: getContract(group.ordinal),
                ordinal: group.ordinal,
                formGroup: getFormGroup(i),
                last,
              }
            "
          ></ng-template>
        </ng-container>
      }
    }
  </form>
}

<!-- TEMPLATES -->
<!-- PANEL FOR DFG WITH CONTRACT -->
<ng-template #panelContract let-group="group" let-formGroup="formGroup" let-ordinal="ordinal" let-last="last">
  <div
    #groupPanel
    class="border group-panel"
    [id]="'groupOrdinal_' + ordinal"
    [class.group-panel-with-contracts]="getHasContractForm(group[0]) && getContractIncluded(group[0])"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="setPanelOpen(group, true)"
      (closed)="setPanelOpen(group, false)"
      [expanded]="group[0]?.ordinal === 1 || isPanelOpen(group)"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title class="text-nowrap">{{ 'Vertrag ' + ordinal }}</mat-panel-title>
          <div class="w-100 d-flex mat-panel-items justify-content-between">
            @if (showGroupError(group[0])) {
              <mat-error class="ml-2"><kf-input-error message="Angaben erforderlich"></kf-input-error></mat-error>
            }
          </div>
        </div>
      </mat-expansion-panel-header>
      <div [class.description]="group.description" [innerHTML]="group.description | safeHtml"></div>
      @if (getHasContractForm(group[0]) && getContractIncluded(group[0])) {
        <div #contractButton class="d-flex my-0 p-1 justify-content-start contract-buttons">
          <ng-template
            *ngTemplateOutlet="contractButtons; context: { data: productStatus, productData, group: group[0] }"
          ></ng-template>
        </div>
      }
      @for (item of group; track item.name; let j = $index) {
        <div class="expansion-panel-body">
          <!-- CONTRACT CHECKBOX -->
          @if (getHasContractForm(group[0])) {
            <div class="mt-4 contract-toggle">
              <div class="mb-4">
                @if (j === 0) {
                  <mat-checkbox
                    kfFocus
                    #contractCheckbox
                    [checked]="getContractIncluded(item)"
                    [attr.data-testcafe]="'contractFor-' + item.dataFieldGroupId"
                    (change)="toggleIncludeExcludeContract(contractCheckbox.checked, item, productData)"
                  >
                    Mit Vertrag?
                  </mat-checkbox>
                }
              </div>
              <h3 class="mat-body-1 font-weight-bold text-nowrap mb-2">{{ item?.name }}</h3>
            </div>
          }

          <ng-template *ngTemplateOutlet="fields; context: { group: item, formGroup, last }"></ng-template>
        </div>
      }
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- PANEL FOR DFG WITHOUT CONTRACT -->
<ng-template #panel let-group="group" let-formGroup="formGroup" let-last="last">
  <div #groupPanel class="border group-panel" [id]="'groupOrdinal_' + group.ordinal">
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="setPanelOpen(group, true)"
      (closed)="setPanelOpen(group, false)"
      [expanded]="group.ordinal === 1 || isPanelOpen(group)"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title>
            <strong class="font-weight-bold text-nowrap">{{ group.name }}</strong>
          </mat-panel-title>

          <div class="w-100 d-flex mat-panel-items justify-content-between">
            @if (showGroupError(group)) {
              <mat-error class="ml-2"><kf-input-error message="Angaben erforderlich"></kf-input-error></mat-error>
            }
          </div>
        </div>
      </mat-expansion-panel-header>
      <div [class.description]="group.description" [innerHTML]="group.description | safeHtml"></div>

      <div class="expansion-panel-body">
        <ng-template *ngTemplateOutlet="fields; context: { group, formGroup, last }"></ng-template>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- DATA FIELDS -->
<ng-template #fields let-group="group" let-formGroup="formGroup" let-last="last">
  <div class="wrapper group-wrapper">
    @for (item of group.dataFields; track item.name) {
      @if (item.dataFieldType !== dataFieldType.hidden) {
        <div class="d-flex flex-column w-50 font-weight-normal pr-3 mb-3 group-content">
          @if (isReadonly(item)) {
            <kf-read-only-field
              aria-describedby="date-format-hint"
              [editable]="false"
              [readOnlyValue]="getValue(formGroup, item)"
              [readOnlyLabel]="getFormControlFieldLabel(item)"
              [errorMessage]="getFieldErrorMessage(formGroup, item)"
            ></kf-read-only-field>
          } @else {
            <!-- item-dataField for number-input-->
            @if (isNumber(item)) {
              <div class="d-flex">
                <mat-form-field
                  [formGroup]="formGroup"
                  class="notranslate"
                  [attr.data-testcafeData]="getTestcafeLabel(group, item)"
                  [attr.data-item]="item"
                >
                  <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                  <input
                    #numberInput
                    [formControlName]="item.dataFieldValueId"
                    autocomplete="off"
                    matInput
                    (change)="handleValueChange(numberInput.value, item)"
                    type="number"
                  />
                  @if (fieldHasError(formGroup, item)) {
                    <mat-error>
                      <kf-input-error [message]="getFieldErrorMessage(formGroup, item)"></kf-input-error
                    ></mat-error>
                  }
                </mat-form-field>
                @if (item.description) {
                  <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
                }
              </div>
            }

            @switch (item.dataFieldType) {
              <!-- item-dataField for text-input-->
              @case (dataFieldType.text) {
                <div class="d-flex">
                  <mat-form-field
                    class="notranslate"
                    [formGroup]="formGroup"
                    [attr.data-item]="item"
                    [attr.data-testcafeData]="getTestcafeLabel(group, item)"
                  >
                    <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                    <input
                      #textInput
                      matInput
                      type="text"
                      autocomplete="off"
                      [formControlName]="item.dataFieldValueId"
                      [attr.aria-label]="'input-' + item.dataFieldValueId"
                      (change)="handleValueChange(textInput.value, item)"
                    />
                    @if (fieldHasError(formGroup, item)) {
                      <mat-error>
                        <kf-input-error [message]="getFieldErrorMessage(formGroup, item)"></kf-input-error>
                      </mat-error>
                    }
                  </mat-form-field>

                  @if (item.description) {
                    <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
                  }
                </div>
              }

              <!-- item-dataField for textarea -->
              @case (dataFieldType.longText) {
                <div class="d-flex">
                  <mat-form-field
                    class="notranslate"
                    [formGroup]="formGroup"
                    [attr.data-item]="item"
                    [attr.testcafeData]="getTestcafeLabel(group, item)"
                  >
                    <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                    <div class="kf-field-char-counter" aria-hidden="true">{{ textArea.value?.length || 0 }}/4000</div>
                    <textarea
                      matInput
                      #textArea
                      ngDefaultControl
                      cdkTextareaAutosize
                      maxLength="4000"
                      [formControlName]="item.dataFieldValueId"
                      (change)="handleValueChange(textArea.value, item)"
                    ></textarea>
                  </mat-form-field>

                  @if (item.description) {
                    <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
                  }
                </div>
              }

              <!-- item-dataField dropdown -->
              @case (dataFieldType.dropdown) {
                <div class="d-flex">
                  <mat-form-field [formGroup]="formGroup" [attr.data-item]="item">
                    <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                    <mat-select
                      #select
                      kfSelectMdc
                      [disabled]="disableFields"
                      [formControlName]="item.dataFieldValueId"
                      (selectionChange)="handleSelectionChange($event, item)"
                    >
                      @for (option of optionDataFactory(item); track option) {
                        <mat-option [value]="option">{{ option }}</mat-option>
                      }
                    </mat-select>
                    @if (fieldHasError(formGroup, item)) {
                      <mat-error>
                        <kf-input-error [message]="getFieldErrorMessage(formGroup, item)"></kf-input-error>
                      </mat-error>
                    }
                  </mat-form-field>

                  @if (item.description) {
                    <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
                  }
                </div>
              }

              <!-- item-datafield checkbox -->
              @case (dataFieldType.checkbox) {
                <div class="dfCheckBox" [formGroup]="formGroup" [attr.item]="item">
                  <mat-checkbox
                    kfFocus
                    #dataFieldCheckbox
                    ngDefaultControl
                    [disabled]="disableFields"
                    [formControlName]="item.dataFieldValueId"
                    [attr.data-testcafe]="'dataField-checkbox-' + item.name + '-' + item.dataFieldValueId"
                    (change)="handleValueChange(dataFieldCheckbox.checked, item)"
                  >
                    {{ item.name }}
                  </mat-checkbox>
                  @if (item.description) {
                    <p [innerHtml]="item.description"></p>
                  }
                </div>
              }

              <!-- item-datafield radio -->
              @case (dataFieldType.radio) {
                <mat-radio-group class="my-radio-group" [formGroup]="formGroup" [attr.item]="item">
                  {{ item.name }}
                  @for (option of optionDataFactory(item); track option) {
                    <mat-radio-button
                      #radioButton
                      kfFocus
                      ngDefaultControl
                      [value]="option"
                      [checked]="item.value === option"
                      [formControlName]="item.dataFieldValueId"
                      (change)="handleValueChange(radioButton.value, item)"
                    >
                      {{ option }}
                    </mat-radio-button>
                  }
                </mat-radio-group>
              }

              <!-- item-datafield date -->
              @case (dataFieldType.date) {
                <mat-form-field [formGroup]="formGroup">
                  <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                  <input
                    #dateField
                    matInput
                    readonly
                    color="primary"
                    tabindex="-1"
                    placeholder="dd.mm.yyyy"
                    autocomplete="off"
                    [min]="getMinDate(item)"
                    [value]="toDate(formGroup.value[item.dataFieldValueId])"
                    [matDatepicker]="picker"
                    [attr.aria-label]="'input-' + item.dataFieldValueId"
                    [formControlName]="item.dataFieldValueId"
                    (dateChange)="handleValueChange(dateField.value, item)"
                  />
                  @if (fieldHasError(formGroup, item)) {
                    <mat-error>
                      <kf-input-error [message]="getFieldErrorMessage(formGroup, item)"></kf-input-error>
                    </mat-error>
                  }

                  <mat-datepicker-toggle [for]="picker" matSuffix aria-label="Datum wählen">
                    <kf-icon name="ic_calendar_24" matDatepickerToggleIcon></kf-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker kfDatepickerPositioning #picker></mat-datepicker>
                </mat-form-field>
              }
            }
          }
        </div>
      }
    }
  </div>
  @if (last) {
    @if (showRemoveDataFieldButton) {
      <button
        mat-flat-button
        color="primary"
        class="datafield-button"
        [disabled]="isLoading"
        (click)="onRemoveDataField(group)"
      >
        <kf-icon name="ic_loeschen_24"></kf-icon>
        <span>Datensatz entfernen</span>
      </button>
    }
  }
</ng-template>

<!-- CONTRACT BUTTONS FOR PANEL HEADER -->
<ng-template #contractButtons let-data="data" let-group="group" let-product="productData">
  @switch (getContractPreviewState(product, group)) {
    <!-- contract preview can be generated -->
    @case ('AVAILABLE') {
      <div class="button-row">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="disabled(group)"
          (click)="generateContractPreview(product, group)"
        >
          <kf-icon name="ic_pdf_24"></kf-icon>
          <span>Vertragsvorschau generieren</span>
        </button>
      </div>
    }

    <!-- contract preview being generated -->
    @case ('IN_PROCESS') {
      <div class="d-flex align-items-center button-row">
        <mat-spinner [diameter]="24" [color]="'primary'" class="mr-1"></mat-spinner>
        <p class="font-weight-bold text-nowrap m-0">Vertragsvorschau wird generiert</p>
      </div>
    }
    <!-- contract preview generated  -->
    @case ('FINISHED') {
      <div class="button-row">
        <div class="button-row-content">
          <button
            mat-stroked-button
            color="primary"
            [disabled]="disabled(group) || !getUpdateNeeded(group)"
            (click)="generateContractPreview(product, group)"
          >
            <kf-icon name="ic_aktualisieren_24"></kf-icon>
            <span>Vertragsvorschau aktualisieren</span>
          </button>

          <button
            mat-stroked-button
            color="primary"
            [disabled]="disabled(group) || getUpdateNeeded(group)"
            (click)="openContractPreviews(product.id, group)"
          >
            <span>Vertragsvorschau öffnen</span>
          </button>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #infoIcon let-description="description">
  <kf-icon
    alt="Details"
    role="button"
    name="ic_infozeichen-mit-kreis_24"
    class="ml-2 mt-2 icon-is-interactive"
    tabindex="0"
    kfTooltipShow="click"
    kfTooltipPosition="after"
    [kfTooltip]="description"
  ></kf-icon>
</ng-template>
