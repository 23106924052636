import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigurationResponse } from '@domain/app/configuration.domain';
import {
  ConsultantResponseItem,
  ContractSummaryStatusItem,
  GetConsultantConsultationResponseItem,
  GetConsultationResponseItem,
  ManageConsultationRequestItem,
  PostCreateConsultationRequestItem,
} from '@domain/app/consultation.domain';
import { ConsultationDocumentsResponse, HubDocumentUploadResponse } from '@domain/app/media.domain';
import { BankUser } from '@domain/hub/bank.domain';
import {
  GetCustomerConsultationResponseItem,
  GetCustomerImportDataRequest,
  GetHubConsultationResponseItem,
} from '@domain/hub/consultation.domain';
import { CustomerRequestItem, CustomerResponseItem } from '@domain/hub/customer.domain';
import { DataFieldGroupItem } from '@domain/hub/datafield.domain';
import { UNDTicketResponse, UNDUploadRequest, UNDUploadResponse } from '@domain/hub/undDomain';
import { environment } from '@environment/environment';
import { CacheService } from '@services/cache-service/cache.service';
import { Observable } from 'rxjs';

export class QueryHub {
  protected _headers = new HttpHeaders().set('Content-Type', 'application/json');
  protected options = { headers: this._headers };

  public api = environment?.api || 'api/v1';
  public hub = environment?.hub || 'hub/v1';

  private portalServiceUrl = '';

  constructor(
    protected http: HttpClient,
    protected cacheService: CacheService
  ) {
    if (environment.platform === 'vp' && !environment.isLocal) {
      this.hub = `serviceproxy/bgzv-backend-guided-service-hub/api/v1`;
      this.api = `serviceproxy/bgzv-backend-consultation-app/api/v1`;
      this.portalServiceUrl = 'bws/serviceproxy';
    }
  }

  // --------------------------------------------- //
  // --------------- API GET METHODS ------------- //
  // --------------------------------------------- //

  getHubConfiguration(renew = false): Observable<ConfigurationResponse> {
    return this.cacheService.fetch(
      'config',
      () => this.http.get<ConfigurationResponse>(`${this.hub}/configuration`),
      renew
    );
  }

  getBankUser(bankId: string, renew = false): Observable<BankUser> {
    return this.cacheService.fetch(
      'bankuser',
      () => this.http.get<BankUser>(`${this.hub}/users/by-external-id/${bankId}`),
      renew
    );
  }

  getBankBankHubDocument(gshId: number | string, customerId: number): Observable<any> {
    return this.http.get<ConsultationDocumentsResponse[]>(
      `${this.hub}/customers/${customerId}/documents/${gshId}/download`,
      { responseType: 'blob' as 'json' }
    );
  }

  getAllCustomers(userId: number = null): Observable<CustomerResponseItem[]> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = userId ? `?userId=${userId}` : '';
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers${query}`);
  }

  getConsultants(): Observable<ConsultantResponseItem[]> {
    return this.http.get<ConsultantResponseItem[]>(`${this.hub}/consultants`);
  }

  getCustomerById(customerId: number): Observable<CustomerResponseItem> {
    return this.http.get<CustomerResponseItem>(`${this.hub}/customers/${customerId}`);
  }

  getCustomerByName(name: string): Observable<CustomerResponseItem[]> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = `?corporationName=${name}`;
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers/by-name${query}`);
  }

  getCustomerByBankId(bankId: number): Observable<CustomerResponseItem[]> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = `?customerIdBank=${bankId}`;
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers/by-bank-id${query}`);
  }

  getDataFieldsGroups(): Observable<DataFieldGroupItem[]> {
    return this.http.get<DataFieldGroupItem[]>(`${this.hub}/data-field-groups`);
  }

  getConsultationsByCustomerId(customerId: number): Observable<GetCustomerConsultationResponseItem[]> {
    return this.http.get<GetCustomerConsultationResponseItem[]>(`${this.hub}/consultations/customers/${customerId}`);
  }

  getConsultationByUrlParams(
    instanceId: string,
    customerNumber: string,
    appointmentId: string
  ): Observable<HttpResponse<GetCustomerConsultationResponseItem>> {
    return this.http.get<GetCustomerConsultationResponseItem>(
      `${this.hub}/consultations/customers/${customerNumber}/instances/${instanceId}/appointments/${appointmentId}`,
      { observe: 'response' }
    );
  }

  getConsultationByConsultant(): Observable<GetConsultantConsultationResponseItem> {
    return this.http.get<GetConsultantConsultationResponseItem>(`${this.hub}/consultations/current-consultant`);
  }

  getConsultationById(bankHubId: string): Observable<GetHubConsultationResponseItem> {
    return this.http.get<GetHubConsultationResponseItem>(`${this.hub}/consultations/${bankHubId}`);
  }

  getConsultationResultPDF(bankHubId: string): Observable<any> {
    const _headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const options = { headers: _headers, responseType: 'blob' as 'json' };
    return this.http.get<any>(`${this.hub}/pdf/consultation-result/${bankHubId}`, options);
  }

  getArchivedPDF(id: string): Observable<any> {
    const _headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const options = { headers: _headers, responseType: 'blob' as 'json' };
    return this.http.get<any>(`${this.hub}/consultations/${id}/pdf/result`, options);
  }

  getNotesAndDocumentsPDF(id: string): Observable<any> {
    const _headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const options = { headers: _headers, responseType: 'blob' as 'json', checkAvailability: 'true' };
    return this.http.get<any>(`${this.hub}/consultations/${id}/pdf/notes-and-documents`, options);
  }

  getConsultationSummary(consultationId: string): Observable<any> {
    return this.http.get<any>(`${this.hub}/consultations/${consultationId}/summary`);
  }

  getSummaryContractStatus(consultationId: string): Observable<ContractSummaryStatusItem> {
    return this.http.get<ContractSummaryStatusItem>(`${this.hub}/consultations/${consultationId}/contracts/status`);
  }

  getMergeDocuments(customerId, documentIds: string): Observable<any> {
    const _headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const options = { headers: _headers, responseType: 'blob' as 'json' };
    return this.http.get<any>(
      `${this.hub}/customers/${customerId}/documents/download?documentIds=${documentIds}`,
      options
    );
  }

  // --------------------------------------------- //
  // --------------- API PUT METHODS ------------- //
  // --------------------------------------------- //

  putCustomerById(customerId: number, data: CustomerRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.hub}/customers/${customerId}`, sendData, this.options);
  }

  putConsultation(bankHubId: string, data: ManageConsultationRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.hub}/consultations/${bankHubId}`, sendData, this.options);
  }

  // --------------------------------------------- //
  // -------------- API DELETE METHODS ----------- //
  // --------------------------------------------- //
  deleteCustomerById(customerId: number): Observable<any> {
    return this.http.delete<any>(`${this.hub}/customers/${customerId}`);
  }

  deleteCustomerFromFavourite(customerId: number, userId: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = `?userId=${userId}`;
    return this.http.delete<any>(`${this.hub}/customers/${customerId}/favourite${query}`);
  }

  deleteConsultation(bankHubId: string): Observable<any> {
    return this.http.delete<any>(`${this.hub}/consultations/${bankHubId}`);
  }

  // --------------------------------------------- //
  // ----------- API POST/PATCH METHODS ---------- //
  // --------------------------------------------- //
  postCustomer(data: CustomerRequestItem): Observable<CustomerResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.post<CustomerResponseItem>(`${this.hub}/customers`, sendData, this.options);
  }

  postCustomerToFavourite(customerId: number, userId: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = `?userId=${userId}`;
    return this.http.post<any>(`${this.hub}/customers/${customerId}/favourite${query}`, null, this.options);
  }

  patchCustomerFavouriteStatus(customerId: number, userId: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = `?userId=${userId}`;
    return this.http.patch<any>(
      `${this.hub}/customers/${customerId}/favourite/update-status${query}`,
      null,
      this.options
    );
  }

  postCustomerImportData(customerId: number, data: GetCustomerImportDataRequest): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.post(`${this.hub}/import-customer-data/${customerId}`, sendData, this.options);
  }

  postArchiveConsultation(consultationId: string) {
    return this.http.post(`${this.hub}/consultations/${consultationId}/archive`, {}, this.options);
  }

  postCreateConsultation(data: PostCreateConsultationRequestItem): Observable<GetConsultationResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.post<GetConsultationResponseItem>(`${this.hub}/consultations`, sendData, this.options);
  }

  postUploadDocument(customerId: number, file: File): Observable<HubDocumentUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<HubDocumentUploadResponse>(` ${this.hub}/customers/${customerId}/documents/upload`, formData);
  }

  // --------------------------------------------- //
  // ------------ UND SERVICE (VP ONLY) ---------- //
  // --------------------------------------------- //

  getUNDTicket(): Observable<UNDTicketResponse> {
    return this.http.get<UNDTicketResponse>(`${this.hub}/und-service/ticket`);
  }

  postUNDUploadFile(data: FormData): Observable<UNDUploadResponse> {
    return this.http.post<UNDUploadResponse>(`/${this.portalServiceUrl}/datasharing/ticket/upload?owner=bgzv`, data);
  }

  postUNDDataToBackend(data: UNDUploadResponse, customerId: number): Observable<HubDocumentUploadResponse> {
    const sendData = {
      undVersionId: data.undID,
      fileName: data.fileName,
    } as UNDUploadRequest;
    return this.http.post<any>(`${this.hub}/customers/${customerId}/documents/upload/und`, sendData, this.options);
  }
}
