@if (mainData && mainData.id) {
  <section class="composition-grid pt-4">
    @if (compositionMedia && compositionMedia.url) {
      <bgzv-image
        class="composition-image"
        [alt]="'illustration'"
        [src]="compositionMedia.url"
        aria-hidden="true"
      ></bgzv-image>
    } @else {
      <bgzv-image
        class="composition-image"
        [alt]="'illustration'"
        [src]="assetPath + '/images/illu_zahnrad_duotone.svg'"
        aria-hidden="true"
      ></bgzv-image>
    }
    <div>
      <ng-container *ngTemplateOutlet="header; context: { data: mainData }"></ng-container>
      @if (!hasVariants && !hasAdditionals) {
        <ng-container *ngTemplateOutlet="productMain; context: { data: mainData }"></ng-container>
      }
      @if (hasVariants) {
        <ng-container *ngTemplateOutlet="productVariant; context: { data: mainData.variants }"></ng-container>
      }
      @if (hasAdditionals) {
        <ng-container *ngTemplateOutlet="productAdditional; context: { data: mainData.additionals }"></ng-container>
      }
    </div>
  </section>
}

<ng-template #header let-mainData="data">
  <div class="d-flex align-items-center">
    <h2
      [debugId]="mainData.id"
      class="mr-3 mb-0"
      [style.maxWidth.%]="90"
      [innerHTML]="truncateText(mainData.name, 50) | safeHtml"
      [kfTooltip]="mainData.name.length > 50 ? mainData.name : null"
      kfTooltipPosition="below"
      kfTooltipShow="hover"
    ></h2>
    <ng-container *ngTemplateOutlet="infoIcon; context: { data: mainData }"></ng-container>
  </div>
  <div class="mat-small line-clamp-3" [style.maxWidth.%]="75" [innerHTML]="description | safeHtml"></div>
</ng-template>

<ng-template #productMain let-mainData="data">
  <div class="composition-product-grid composition-product-box pl-3 mt-2">
    <div class="d-flex justify-content-start">
      <div *ngTemplateOutlet="productItem; context: { data: mainData }"></div>
    </div>
    <ng-container *ngTemplateOutlet="quantityItem; context: { data: mainData }"></ng-container>
    <ng-container *ngTemplateOutlet="priceItem; context: { price: getPrice(mainData) }"></ng-container>
    <ng-container *ngTemplateOutlet="docIcon; context: { data: mainData }"></ng-container>
    <ng-container *ngTemplateOutlet="dataIcon; context: { data: mainData }"></ng-container>
    <ng-container *ngTemplateOutlet="infoIcon; context: { data: mainData }"></ng-container>
  </div>
</ng-template>

<ng-template #productVariant let-variantData="data">
  @for (variant of variantData; track variant) {
    <div class="composition-product-grid composition-product-box pl-3 mt-2">
      <div class="d-flex justify-content-start">
        <div *ngTemplateOutlet="productItem; context: { data: variant }"></div>
      </div>
      <ng-container *ngTemplateOutlet="quantityItem; context: { data: variant }"></ng-container>
      <ng-container *ngTemplateOutlet="priceItem; context: { price: getPrice(variant) }"></ng-container>
      <ng-container *ngTemplateOutlet="docIcon; context: { data: variant }"></ng-container>
      <ng-container *ngTemplateOutlet="dataIcon; context: { data: variant }"></ng-container>
      <ng-container *ngTemplateOutlet="infoIcon; context: { data: variant }"></ng-container>
    </div>
  }
</ng-template>

<ng-template #productAdditional let-additionalData="data">
  @if (hasVisibleAdditionals(additionalData)) {
    <div class="mat-small font-weight-bold mt-4">Ergänzende Lösungen</div>
    @for (additional of additionalData; track additional) {
      @if (isVisibleAdditional(additional)) {
        <div class="composition-product-grid composition-product-box pl-3 mt-2">
          <div class="d-flex justify-content-start">
            <div *ngTemplateOutlet="productItem; context: { data: additional }"></div>
          </div>
          <ng-container *ngTemplateOutlet="quantityItem; context: { data: additional }"></ng-container>
          <ng-container *ngTemplateOutlet="priceItem; context: { price: getPrice(additional) }"></ng-container>
          <ng-container *ngTemplateOutlet="docIcon; context: { data: additional }"></ng-container>
          <ng-container *ngTemplateOutlet="dataIcon; context: { data: additional }"></ng-container>
          <ng-container *ngTemplateOutlet="infoIcon; context: { data: additional }"></ng-container>
        </div>
      }
    }
  }
  <div class="mt-2"></div>
  <button mat-button color="primary" (click)="showExtraProductDetails()" [disabled]="loading">
    <span>Weitere Lösungen</span>
  </button>
</ng-template>

<ng-template #productItem let-data="data">
  <div class="d-flex align-items-center">
    <mat-checkbox
      #productElementBox
      kfFocus
      [attr.data-testcafe]="'itemCompositionMain-checkbox-check-' + getTestcafeId(data.name)"
      [disabled]="loading"
      [checked]="data.selected"
      [disableRipple]="true"
      (click)="$event.stopPropagation()"
      (change)="setProductSelectedState(data.id, $event)"
      style="margin: 0 0.75rem 0 0"
      [color]="'grey'"
      [aria-label]="getCheckboxLabel(data.name)"
      ><span [innerHTML]="data.name || 'NO TITLE' | safeHtml"></span
    ></mat-checkbox>
    @if (isRecommended(data)) {
      <div class="tag-info tag-neutral mb-1 ml-3">
        <div class="tag-info-text">Empfohlen</div>
      </div>
    }
  </div>
</ng-template>

<ng-template #quantityItem let-data="data">
  @if (data.quantityVisibility && data.selected && data.selectedQuantity > 0) {
    <answer-number-input
      [id]="data.id"
      #productNum
      class="composition-place-center"
      [testcafeData]="getTestcafeId(data.name)"
      [minValue]="0"
      [maxValue]="data.quantityMaxAmount ? data.quantityMaxAmount : 100"
      [disabled]="loading"
      [defaultNumber]="data.selected ? data.selectedQuantity : data.quantityDefaultValue"
      [showRemoveIcon]="productNum?.currentNumber <= 1 || data.selectedQuantity <= 1 || data.quantityDefaultValue <= 1"
      (currentNumberChanged)="setProductQuantity(data.id, $event)"
    >
    </answer-number-input>
  } @else {
    <div class="composition-place-center"></div>
  }
</ng-template>

<ng-template #priceItem let-price="price">
  <div class="price composition-place-end" markNegativePrice>{{ price }}</div>
</ng-template>

<!-- ------ ICON BUTTON TEMPLATES ------ -->
<ng-template #docIcon let-data="data">
  @if (!hasDocuments(data.medias) || !data.selected) {
    <div class="composition-place-center"></div>
  } @else {
    <div class="composition-place-center">
      <button
        [attr.data-testcafe]="'itemComposition-button-documents-' + getTestcafeId(data.name)"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [attr.aria-label]="(data.medias.length > 1 ? 'Dokumente' : 'Dokument') + ' anzeigen'"
        [kfTooltip]="(data.medias.length > 1 ? 'Dokumente' : 'Dokument') + ' zu: ' + data.name"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
      >
        <kf-icon name="ic_datei_24"></kf-icon>
      </button>
      <mat-menu #menu="matMenu">
        @for (document of onlyDocuments(data.medias); track document) {
          <ng-container class="d-flex flex-column">
            <button mat-menu-item (click)="showDocument(document, $event)">
              {{ document.name + ' (' + document.type + ')' }}
            </button>
          </ng-container>
        }
      </mat-menu>
    </div>
  }
</ng-template>

<ng-template #dataIcon let-data="data">
  @if (!hasDataField(data.completeStatus) || !data.selected) {
    <div class="composition-place-center"></div>
  } @else {
    <button
      class="composition-place-center"
      [attr.data-testcafe]="'itemComposition-button-data-' + getTestcafeId(data.name)"
      mat-icon-button
      (click)="onDataFieldsClicked(data.id)"
      aria-label="Weitere Angaben anzeigen"
      [kfTooltip]="'Weitere Angaben zu: ' + data.name"
      kfTooltipPosition="below"
      kfTooltipShow="hover"
    >
      <kf-icon name="ic_vertragdetails_24"></kf-icon>
    </button>
  }
</ng-template>

<ng-template #infoIcon let-data="data">
  <button
    class="composition-place-center"
    [attr.data-testcafe]="'itemComposition-button-details'"
    mat-icon-button
    (click)="showProductDetails(data.id)"
    aria-label="Details anzeigen"
    [kfTooltip]="'Details zu: ' + data.name"
    kfTooltipPosition="below"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
  </button>
</ng-template>
