import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AnswerSliderComponent } from '@components/answers/answer-slider/answer-slider.component';
import { BgzvComponentsModule } from '@components/components.module';
import { DndDirective } from '@components/documents-upload/dnd.directive';
import { RoutingPathOverlay } from '@enums';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { OverlayAgendaEditComponent } from '@overlays/overlay-agenda-edit/overlay-agenda-edit.component';
import { OverlayCartComponent } from '@overlays/overlay-cart/overlay-cart.component';
import { CompareAccountsInputsComponent } from '@overlays/overlay-compare-accounts/compare-accounts-inputs/compare-accounts-inputs.component';
import { OverlayCompareAccountsComponent } from '@overlays/overlay-compare-accounts/overlay-compare-accounts.component';
import { ProductVariantCardComponent } from '@overlays/overlay-compare-accounts/product-variant-card/product-variant-card.component';
import { OverlayConsultationNewComponent } from '@overlays/overlay-consultation-new/overlay-consultation-new.component';
import { OverlayCustomerImportComponent } from '@overlays/overlay-customer-import/overlay-customer-import.component';
import { OverlayCustomerNewComponent } from '@overlays/overlay-customer-new/overlay-customer-new.component';
import { OverlayDebugComponent } from '@overlays/overlay-debug/overlay-debug.component';
import { OverlayDrawComponent } from '@overlays/overlay-draw/overlay-draw.component';
import { DocumentsMenuComponent } from '@overlays/overlay-notes/documents-menu/documents-menu.component';
import { NotesMenuComponent } from '@overlays/overlay-notes/notes-menu/notes-menu.component';
import { SelectNoteButtonComponent } from '@overlays/overlay-notes/notes-menu/select-note-button/select-note-button.component';
import { OverlayNotesComponent } from '@overlays/overlay-notes/overlay-notes.component';
import { OverlaySearchMenuComponent } from '@overlays/overlay-search/overlay-search-menu.component';
import { SearchNavigationStripComponent } from '@overlays/overlay-search/search-navigation-strip/search-navigation-strip.component';
import { SearchQuestionWorldColumnComponent } from '@overlays/overlay-search/search-question-world/search-question-world-column.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SearchWorldColumnComponent } from './overlay-search/search-world-column/search-world-column.component';

export const overlayRoutes: Routes = [
  // overlay paths
  { path: RoutingPathOverlay.Notes.valueOf(), component: OverlayNotesComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.Cart.valueOf(), component: OverlayCartComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.Draw.valueOf(), component: OverlayDrawComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.ConsultNew.valueOf(), component: OverlayConsultationNewComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.CustomerNew.valueOf(), component: OverlayCustomerNewComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.Search.valueOf(), component: OverlaySearchMenuComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.CompareAccounts.valueOf(), component: OverlayCompareAccountsComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.CustomerImport.valueOf(), component: OverlayCustomerImportComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.AgendaEdit.valueOf(), component: OverlayAgendaEditComponent, outlet: 'overlay' },
  { path: RoutingPathOverlay.Debug.valueOf(), component: OverlayDebugComponent, outlet: 'overlay' },
];

@NgModule({
  declarations: [
    CompareAccountsInputsComponent,
    DocumentsMenuComponent,
    NotesMenuComponent,
    OverlayCartComponent,
    OverlayCompareAccountsComponent,
    OverlayConsultationNewComponent,
    OverlayCustomerImportComponent,
    OverlayCustomerNewComponent,
    OverlayCustomerNewComponent,
    OverlayDebugComponent,
    OverlayDrawComponent,
    OverlayNotesComponent,
    OverlaySearchMenuComponent,
    OverlayAgendaEditComponent,
    ProductVariantCardComponent,
    SearchNavigationStripComponent,
    SearchWorldColumnComponent,
    SearchQuestionWorldColumnComponent,
    SelectNoteButtonComponent,
  ],
  exports: [RouterModule, DndDirective],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    KfThemeModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule.setOpts('de-DE'),

    BgzvMarginalsModule,
    BgzvComponentsModule,
    BgzvUtilsModule,
    CKEditorModule,
    AnswerSliderComponent,
  ],
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
})
export class BgzvOverlayModule {}
